const initialState = {
  profile: { user_isAuth: false },
  users: [],
  user: {},
  absen: {},
  error: null,
};
const userReducer = (state = initialState, action) => {
  switch (action.type) {
    case "USER_LOGIN":
      return {
        ...state,
        profile: action.payload,
      };
    case "EROR_LOGIN":
      return {
        ...state,
        error: action.payload,
      };
    case "UPDATE_USER":
      return {
        ...state,
        profile: {
          ...state.profile,
          ...action.payload,
        },
      };
    case "LOGOUT_USER":
      return {
        profile: { user_isAuth: false },
      };
    case "ABSEN_USER":
      return { ...state, absen: action.payload };
    default:
      return state;
  }
};
export default userReducer;
