import Swal from "sweetalert";
import axios from "axios";
import { io } from "socket.io-client";

export const login = (data, history, setLoading) => (dispatch) => {
  setLoading(true);

  axios
    .post(`${process.env.REACT_APP_BASE_URL}/users/login`, data)
    .then((response) => {
      const resultLogin = response.data.data;
      // const url = `kalasanerp-api.lyrid.id:8000/orders`;
      // // const socket = io(url, {
      // //   query: { access_token: resultLogin.token },
      // // });
      const userLogin = { ...resultLogin, user_isAuth: true };

      setLoading(false);

      const url = `http://localhost:8011`;

      const socket = io(url, {
        reconnection: false,
      });

      socket.on("connect", () => {
        console.log("Connected to Socket.IO server frontend");
      });

      socket.emit("login", resultLogin.employee_id);
      dispatch({ type: "USER_LOGIN", payload: userLogin });
      Swal("Login berhasil!", "", "success");
    })
    .catch((error) => {
      setLoading(false);
      dispatch({
        type: "EROR_LOGIN",
        payload: error?.response?.data?.message,
      });
      Swal("Login Gagal", error?.response?.data?.message || "", "error");
    });
};

export const logout = (history) => (dispatch) => {
  dispatch({ type: "LOGOUT_USER" });
  history.push("/login");
};
