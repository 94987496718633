import React from "react";

const TabletMode = React.lazy(() =>
  import("./views/kalasanPages/pengaturan/tabletMode/tabletMode")
);
const Stock = React.lazy(() => import("./views/kalasanPages/tablet/stok/Stok"));

const routesKitchen = [
  {
    path: "/tablet-mode",
    exact: true,
    name: "Mode Tablet",
    component: TabletMode,
  },
  {
    path: "/tablet-mode/stok",
    name: "Stok Tablet",
    component: Stock,
  },
];

export default routesKitchen;
